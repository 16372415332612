const PromotionsCMS = () =>
  import(/* webpackChunkName: "cms" */'common/pages/PromotionsCMS');
const LBComunnityPlussCMS = () =>
  import(/* webpackChunkName: "cms" */'common/pages/Promotions/LBComunnityPlussCMS');

const PROMOTIONS = '/promotions'

export const promotionRoutes = [
  { name: 'promotions', path: `${PROMOTIONS}`, component: PromotionsCMS },
  { name: 'lbcommunity-plus', path: `${PROMOTIONS}/lbcommunity-plus`, component: LBComunnityPlussCMS }
]
