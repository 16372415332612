/**
 * List of modal names
 */
export enum ModalList {
  Review = 'm-modal-review',
  Auth = 'm-modal-authentication',
  SizeGuide = 'm-modal-size-guide',
  TermsAndConditions = 'm-modal-terms-and-conditions',
  FeatureNotImplemented = 'm-modal-feature-not-implemented',
  LanguageSwitcher = 'm-modal-language-switcher',
  OrderConfirmation = 'm-modal-order-confirmation',
  Newsletter = 'm-modal-newsletter',
  LoyaltyRewardsDetails = 'm-modal-rewards-details',
  LoyaltyRewardsDetailsNew = 'm-modal-rewards-details-new',
  LoyaltyRedeemSuccess = 'm-modal-claim-success',
  LoyaltyClaimedRewardsDetails = 'm-modal-claimed-rewards-details',
  MModalWishlistNotification = 'm-modal-wishlist-notification',
  MModalWishListEdit = 'm-modal-wishlist-edit',
  MModalWishlistItemRemove = 'm-modal-wishlist-item-remove',
  MModalOosSubscriptionItemRemove = 'm-modal-oos-subscription-item-remove',
  MModalRestockSuccess = 'm-modal-restock-success',
  MModalStoreAvailability = 'm-modal-store-availability',
  MModalReferFriend = 'm-modal-refer-friend',
  MModalPreorderWarning = 'm-modal-preorder-warning',
  MMegaMenuMobileModal = 'm-mega-menu-mobile-modal',
  MEditCartItemModal = 'm-edit-cart-item-modal',
  MFailedOrderModal = 'm-failed-order-modal'
}

/**
 * List of modal components files
 */
export const modalComponents = new Map([
  [ModalList.Auth, () => import(/* webpackChunkName: "m-modal-authentication" */ 'common/components/molecules/modals/m-modal-authentication.vue')],
  [ModalList.SizeGuide, () => import(/* webpackChunkName: "m-modal-size-guide" */ 'common/components/molecules/modals/m-modal-size-guide.vue')],
  [ModalList.TermsAndConditions, () => import(/* webpackChunkName: "m-modal-terms-and-conditions" */ 'common/components/molecules/modals/m-modal-terms-and-conditions.vue')],
  [ModalList.FeatureNotImplemented, () => import(/* webpackChunkName: "m-modal-feature-not-implemented" */ 'common/components/molecules/modals/m-modal-feature-not-implemented.vue')],
  [ModalList.LanguageSwitcher, () => import(/* webpackChunkName: "m-modal-language-switcher" */ 'common/components/molecules/modals/m-modal-language-switcher.vue')],
  [ModalList.OrderConfirmation, () => import(/* webpackChunkName: "m-modal-order-confirmation" */ 'common/components/molecules/modals/m-modal-order-confirmation.vue')],
  [ModalList.Newsletter, () => import(/* webpackChunkName: "m-modal-newsletter" */ 'common/components/molecules/modals/m-modal-newsletter.vue')],
  [ModalList.LoyaltyRewardsDetails, () => import(/* webpackChunkName: "m-modal-rewards-details" */ 'common/components/molecules/modals/loyalty/m-modal-rewards-details.vue')],
  [ModalList.LoyaltyRewardsDetailsNew, () => import(/* webpackChunkName: "m-modal-rewards-details-new" */ 'common/components/molecules/modals/loyalty/m-modal-rewards-details-new.vue')],
  [ModalList.LoyaltyRedeemSuccess, () => import(/* webpackChunkName: "m-modal-claim-success" */ 'common/components/molecules/modals/loyalty/m-modal-claim-success.vue')],
  [ModalList.LoyaltyClaimedRewardsDetails, () => import(/* webpackChunkName: "m-modal-claimed-rewards-details" */ 'common/components/molecules/modals/loyalty/m-modal-claimed-rewards-details.vue')],
  [ModalList.MModalWishlistNotification, () => import(/* webpackChunkName: "m-modal-wishlist-notification" */ 'common/components/molecules/modals/wishlist/m-modal-wishlist-notification.vue')],
  [ModalList.MModalWishListEdit, () => import(/* webpackChunkName: "m-modal-wishlist-edit" */ 'common/components/molecules/modals/wishlist/m-modal-wishlist-edit.vue')],
  [ModalList.MModalWishlistItemRemove, () => import(/* webpackChunkName: "m-modal-wishlist-item-remove" */ 'common/components/molecules/modals/wishlist/m-modal-wishlist-item-remove.vue')],
  [ModalList.MModalStoreAvailability, () => import(/* m-modal-store-availability" */ 'common/components/molecules/store-availability/m-modal-store-availability.vue')],
  [ModalList.MModalOosSubscriptionItemRemove, () => import(/* webpackChunkName: "m-modal-oos-subscription-item-remove" */ 'common/components/molecules/modals/oos-subscriptions/m-modal-oos-subscription-item-remove.vue')],
  [ModalList.MModalRestockSuccess, () => import(/* webpackChunkName: "m-modal-restock-success" */ 'common/components/molecules/modals/oos-subscriptions/m-modal-restock-success.vue')],
  [ModalList.MModalReferFriend, () => import(/* webpackChunkName: "m-modal-refer-friend" */ 'common/components/molecules/modals/loyalty/m-modal-refer-friend.vue')],
  [ModalList.MModalPreorderWarning, () => import(/* webpackChunkName: "m-modal-preorder-warning" */ 'common/components/molecules/modals/m-modal-preorder-warning.vue')],
  [ModalList.MMegaMenuMobileModal, () => import(/* webpackChunkName: "m-modal-preorder-warning" */ 'common/components/organisms/mega-menu/mobile/o-mega-menu-mobile-modal.vue')],
  [ModalList.MEditCartItemModal, () => import(/* webpackChunkName: "m-edit-cart-item-modal" */ 'common/components/molecules/modals/cart/m-edit-cart-item-modal.vue')],
  [ModalList.MFailedOrderModal, () => import(/* webpackChunkName: "m-failed-order-modal" */ 'common/components/molecules/modals/order/m-failed-order-modal.vue')]
])

/**
 * Optional payload for modal
 */
export const modalPayload = new Map([])

export const modalStore = {
  state: {
    activeModals: [],
    loadedModals: []
  },
  getters: {
    activeModals: state => state.activeModals.map((name) => ({
      name,
      payload: modalPayload.get(name)
    })),
    loadedModals: state => state.loadedModals
  },
  mutations: {
    openModal (state, name) {
      if (!state.loadedModals.includes(name)) {
        state.loadedModals = [...state.loadedModals, name];
      }
      state.activeModals = [...state.activeModals, name];
    },
    closeModal (state, name) {
      state.activeModals = state.activeModals.filter(modal => modal !== name);
    }
  },
  actions: {
    openModal ({ commit }, { name, payload }) {
      modalPayload.set(name, payload)
      commit('closeModal', name)
      commit('openModal', name)
    },
    closeModal ({ commit }, { name }) {
      modalPayload.delete(name)
      commit('closeModal', name)
    }
  }
}
