import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { CartNextModule } from 'src/modules/cart-next'
import { CheckoutNextModule } from 'src/modules/checkout-next'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { GoogleTagManagerModule } from 'src/modules/google-tag-manager';
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { PaymentBackendMethodsModule } from 'src/modules/payment-backend-methods'
import { HotjarModule } from 'src/modules/hotjar'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { UserModule } from '@vue-storefront/core/modules/user'
import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { ProductNextModule } from 'src/modules/catalog/store/product';
import { CategoryNextModule } from 'src/modules/category-next'
import { UserNextModule } from 'src/modules/user-next'
import { OrderNextModule } from 'src/modules/order-next'
import { StockNextModule } from 'src/modules/stock-next'
import { FeaturehubModule } from 'src/modules/feature-hub'
import { LoyaltyModule } from 'src/modules/loyalty/store'
import { EmarsysModule } from 'src/modules/emarsys/store';
import { AddressModule } from '../../../modules/address';
import { CurrencyRateModule } from 'src/modules/currency-coverter';
import { StoryBlokModule } from 'src/modules/storyblok/store';
import { WishlistNextModule } from 'src/modules/wishlist-next/store';
import { storeAvailabilityModule } from 'src/modules/store-availability/store';
import { restockNotificationModule } from 'src/modules/restock-notification/store';
import { CatalogEsModule } from 'src/modules/catalog-es';
import { CartV1Store } from 'src/modules/cart-v1';
import { CheckoutV1Store } from 'src/modules/checkout-v1';
import { cacheTagsModule } from 'src/modules/cache-tags';

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules (cacheTags) {
  registerModule((params) => {
    UrlModule({ ...params, cacheTags })
  })
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(BreadcrumbsModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(GoogleTagManagerModule)
  registerModule(CartNextModule)
  registerModule(CheckoutNextModule)
  registerModule(ProductNextModule)
  registerModule(CategoryNextModule)
  registerModule(OrderModule)
  registerModule(OrderNextModule)
  registerModule(UserNextModule)
  registerModule(HotjarModule)
  registerModule(FeaturehubModule);
  registerModule(LoyaltyModule);
  registerModule(EmarsysModule);
  registerModule(AddressModule);
  registerModule(CurrencyRateModule);
  registerModule(StoryBlokModule);
  registerModule(WishlistNextModule);
  registerModule(storeAvailabilityModule);
  registerModule(restockNotificationModule)
  registerModule(StockNextModule);
  registerModule(CatalogEsModule);
  registerModule(CartV1Store);
  registerModule(CheckoutV1Store);
  registerModule(cacheTagsModule);
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
]
