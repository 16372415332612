import { once, isServer } from '@vue-storefront/core/helpers';
import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore';
import '@vue-storefront/core/lib/passive-listeners';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { claimsStore } from 'theme/store/claims';
import { defaultContentStore } from 'theme/store/default-content';
import { homepageStore } from 'theme/store/homepage';
import { productpageStore } from 'theme/store/productpage';
import { uiStore } from 'theme/store/ui';
import { promotedStore } from 'theme/store/promoted-offers';
import { instagramStore } from 'theme/store/instagram-images';
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import VueProgressBar from 'vue-progressbar';
import App from './App';
import routes from './router';
import { module as cartModule } from './store/cart';
import { awsPersonalizeStore } from '../../modules/aws-personalize/store';
import isElementInViewPort from 'common/directives/isElementInViewPort';
import { ObserveVisibility } from 'vue-observe-visibility'
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { v4 as uuidv4 } from 'uuid';

Vue.directive('observe-visibility', ObserveVisibility)
Vue.config.ignoredElements = ['klarna-placement']

if (!isServer) {
  const { Carousel, Slide } = require('vue-carousel');
  const { Skeleton } = require('vue-loading-skeleton');

  Vue.directive('element-is-in-view-port', isElementInViewPort);
  Vue.component('carousel', Carousel);
  Vue.component('slide', Slide);
  Vue.component('skeleton', Skeleton);
}

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
  Vue.component('inline-svg', InlineSvg);
});

const themeEntry = App;
function initTheme (app, router, store, config) {
  store.registerModule('themeCart', cartModule);
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes, 10);

  StorageManager.init('claims');
  store.registerModule('claims', claimsStore);
  store.registerModule('homepage', homepageStore);
  store.registerModule('productpage', productpageStore);
  store.registerModule('ui', uiStore);
  store.registerModule('promoted', promotedStore);
  store.registerModule('instagram', instagramStore);
  store.registerModule('defaultContent', defaultContentStore);
  store.registerModule('awsPersonalize', awsPersonalizeStore);

  const environment = config?.server?.isProduction ? 'production' : 'staging';

  Sentry.setTag('environment', environment);
  Sentry.init({
    Vue,
    dsn: config?.server?.sentryDsn,
    environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['*']
      })
    ],
    maxValueLength: 5000,
    sampleRate: 1.0,
    tracesSampleRate: 1.0
  });
  Sentry.configureScope(scope => {
    let transaction_id = uuidv4()
    scope.setTag('store', 'us');
    scope.setTag('environment', environment);
    scope.setTag('transaction_id', transaction_id);
    scope.setUser({
      'transaction_id': transaction_id
    })
  });
}

export { themeEntry, initTheme };
