<template>
  <div id="app">
    <inline-svg
      :src="require(`common/assets/sprite.svg`)"
      v-show="false"
    />
    <inline-svg
      :src="require(`common/assets/uvpsprite.svg`)"
      v-show="false"
    />
    <DefaultLayout>
      <router-view />
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from './layouts/Default';
import emarsysPopUpTracking from 'src/common/mixins/emarsysPopUpTracking';

export default {
  components: {
    DefaultLayout
  },
  mixins: [emarsysPopUpTracking]
};
</script>

<style lang="scss" src="../../common/assets/css/global/main.scss"></style>
<style lang="scss" src="theme/css/main.scss"></style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#cms-content {
  margin-top: 145px !important;
  @include for-mobile {
    margin-top: 106px !important;
  }
}
* {
  outline: none;
}
input {
  display: block !important;
}
body {
  --overlay-z-index: 1;
  --sidebar-aside-z-index: 2;
  --bottom-navigation-height: 3.75rem;
  --bar-height: 3.125rem;
  --notification-font-size: var(--font_size_75);
  font-family: var(--font-secondary);
  margin: 0;
  padding: 0;
  color: var(--neutral-900);
  a {
    text-decoration: unset;
    color: unset;
    cursor: pointer;
    &:hover {
      color: unset;
    }
  }
}

#viewport {
  position: relative;
}

@include for-desktop {
  .sidebar {
    &__microcart {
      --sidebar-aside-width: 700px;
    }
  }
}

.alert-warning {
  font-size: var(--font_size_75);
  line-height: 24px;
  color: var(--warning-base);
  background: var(--warning-100);
  padding: calc(var(--spacer-sm) - 5px) 0;
  font-weight: 400;
  text-align: center;
}
.error-text {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: var(--font_size_50);
  line-height: 24px;
  color: var(--danger-500);
  line-height: 1rem;
  margin-top: var(--spacer-xs);
}
.success-text {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: var(--font_size_50);
  line-height: 24px;
  color: var(--success-base);
  line-height: 1rem;
  margin-top: var(--spacer-xs);
}
.error-border {
  border: 1px solid var(--danger-500) !important;
}
.page-header {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28px;
  color: var(--neutral-900);
  margin-top: 0;
  margin-bottom: calc(var(--spacer-2xl) - 35px);
  @media (max-width: 766px) {
    font-size: 25px;
    margin-bottom: 29px;
  }
}
.icon-xs {
  width: 8px;
  height: auto;
}
.icon-sm {
  width: 12px;
  height: auto;
}
.icon-base {
  width: 15px;
  height: auto;
}
.icon-lg {
  width: 20px;
  height: auto;
}
.icon-xl {
  width: 25px;
  height: auto;
}
.icon-2xl {
  width: 30px;
  height: auto;
}
.icon-standard {
  width: 24px;
  height: 24px;
}
.modal-open {
  overflow: hidden;
}
</style>
